
  import { Getter } from "vuex-class";
  import { Component, Vue, Prop, Emit, Ref, Watch } from "vue-property-decorator";
  import "vue-mj-daterangepicker-custom-presets/dist/vue-mj-daterangepicker.css";
  import userImage from "@/components/general/user-image.vue";

  interface UserOption {
    first_name: string;
    last_name: string;
    preview_url: string | null;
    id: number;
  }

  @Component({
    components: {
      userImage,
    },
  })
  export default class FormUserSelect extends Vue {
    @Getter("auth/me") me!: CurrentAccount;

    @Prop() users!: UserOption[];

    @Prop({ default: () => [] }) value!: UserOption[];

    @Prop({ default: false }) searchable!: boolean;

    @Prop({ default: true }) multiple!: boolean;

    @Prop({ default: false }) canSelectAll!: boolean;

    @Prop({ default: "center" }) direction!: "center" | "right" | "left";

    @Ref() searchInput!: HTMLInputElement;

    show: boolean = false;

    allSelected: boolean = false;

    selected: number[] = [];

    search = "";

    get clickOutsideConfig() {
      return {
        handler: this.closeUserSelect,
        isActive: this.show,
        capture: true,
      };
    }

    get selectedUsers() {
      if (!this.users) {
        return [];
      }

      return this.users.filter((u) => this.selected.includes(Number(u.id)));
    }

    get searchedUsers() {
      return this.users.filter((u) => `${u.first_name.toLowerCase()} ${u.last_name.toLowerCase()}`.includes(this.search.toLowerCase()));
    }

    closeUserSelect() {
      this.show = false;
    }

    toggleSelected(option: UserOption, canDelete: boolean = false) {
      const id = Number(option.id);
      if (this.selected.includes(id) && !canDelete) {
        return;
      }

      if (this.multiple) {
        this.handleMultiSelect(id);
      } else {
        this.handleSingleSelect(id);
      }
    }

    toggleAll() {
      this.allSelected = !this.allSelected;

      if (this.allSelected) {
        this.selected = this.users.map((m) => Number(m.id));
      } else {
        this.selected = [this.me.id];
      }

      this.handleInput();

      this.closeUserSelect();
    }

    handleMultiSelect(id: number) {
      if (this.selected.includes(id)) {
        this.selected.splice(this.selected.indexOf(id), 1);
      } else {
        this.selected.push(id);
      }

      this.handleInput();
    }

    handleSingleSelect(id: number) {
      if (this.selected.includes(id)) {
        this.selected = [];

        this.handleInput();

        this.closeUserSelect();

        return;
      }

      this.selected = [Number(id)];

      this.handleInput();

      this.closeUserSelect();
    }

    generateTooltip(content: string) {
      return { content: content, offset: [0, 12], delay: { show: 500, hide: 100 }, placement: "top" };
    }

    isOptionSelected(option: UserOption): boolean {
      return this.selected.includes(Number(option.id));
    }

    @Emit("input")
    handleInput() {
      return this.selected;
    }

    @Watch("show")
    handleShown(isShown: boolean) {
      if (isShown) {
        this.$emit("open");
      }
      this.$nextTick(() => {
        if (this.searchable && this.searchInput && isShown) {
          this.searchInput.focus();
        }
      });
    }

    @Watch("value", { immediate: true })
    handleValueChanged(newValue: number[]) {
      this.selected = newValue;

      if (this.selected && this.users) {
        this.allSelected = this.selected.length === this.users.length;
      }
    }
  }
